/**
 * Custom fetch query that re-authorizes using the refresh token
 * @see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
 */
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query'
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query'
import type { Alert } from '@tunasong/models'
import { isOnline, isTest, logger } from '@tunasong/models'
import { Mutex } from 'async-mutex'
import invariant from 'tiny-invariant'
import { OAuth } from '../auth/index.js'
import { notificationsSlice } from '../features/notifications/notifications-slice.js'
import { userSlice } from '../features/user/user-slice.js'

const MAX_RETRIES = 1

/** @note these must be allowed by CORS for local dev */
export const RTK_QUERY_ARGS_HEADER = 'x-rtk-query-args'
export const RTK_QUERY_API_NAME_HEADER = 'x-rtk-query-api-name'
export const RTK_QUERY_ENDPOINT_NAME_HEADER = 'x-rtk-query-endpoint-name'

const mutex = new Mutex()

export interface Meta {
  eTag: string | null
  resumeKey: string | null
}
export interface ExtraOptions {
  skipAuthorization?: boolean
}

/**
 * Helper to cache the query spec
 * @note The Service Worker must broadcast changes with the endpoint name and cache key
 * for the Redux store to update. We handle this in `sw-sync.ts`.
 */
export const createCachedQuerySpecHelper =
  (apiName: string) =>
  <T, S extends FetchArgs>(endpointName: string, callback: (queryArgs: T) => S) =>
  (queryArgs: T) => {
    const args = callback(queryArgs)
    return {
      ...args,
      headers: {
        [RTK_QUERY_API_NAME_HEADER]: apiName,
        [RTK_QUERY_ENDPOINT_NAME_HEADER]: endpointName,
        // We cannot use arbitrary data in the headers, so we'll Base64 encode it to be safe
        [RTK_QUERY_ARGS_HEADER]: encodeURIComponent(JSON.stringify(queryArgs)),
        ...args.headers,
      },
    } satisfies S
  }

/** Base Query extensions */

const createBaseQuery = ({ baseUrl, extraOptions }: { baseUrl: string; extraOptions: ExtraOptions }) =>
  fetchBaseQuery({
    prepareHeaders: (headers, api) => {
      const { getState } = api

      /** Set authorization header if not set  */
      if (extraOptions?.skipAuthorization) {
        return headers
      }
      /** Set the authorization header */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const token = (getState() as any).user.authData?.idToken.jwtToken

      // In test, we don't enforce the token to be set
      if (!isTest()) {
        invariant(token, 'No token found')
      }

      headers.set('Authorization', `Bearer ${token}`)

      return headers
    },
    baseUrl,
  })

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions,
  Meta & FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = api.getState() as any
  const baseUrl = state.config.apiBaseUrl

  const baseQuery: ReturnType<typeof createBaseQuery> = retry(createBaseQuery({ baseUrl, extraOptions }), {
    maxRetries: MAX_RETRIES,
  })

  let result = await baseQuery(args, api, extraOptions)

  if (result.error?.status === 401) {
    // checking whether the mutex is locked

    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        /**
         * We have two possible conditions here. We have a valid session, or we do not.
         * If we have a valid session, we should not retry as this is an item we don't have access to.
         * If we don't have a valid session, retry the request after refreshing the session.
         */

        /** We require valid Authdata in Redux  */
        const validSession = Boolean(state.user.authData) && !OAuth.isSessionExpired(state.user.authData)
        if (validSession) {
          /**
           * Throws the error - @see https://github.com/reduxjs/redux-toolkit/issues/3789
           * This is a bug in Redux Toolkit.
           * So for clients, they will receive the error, but as {error: {error}}
           */
          retry.fail(result.error)
        }
        /** We don't have a valid session, so get one */
        const sess = await OAuth.getSession()
        if (sess?.idToken.payload.sub) {
          api.dispatch(userSlice.actions.setAuth(sess))
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          OAuth.logout()
          api.dispatch(userSlice.actions.signout())
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  if (result.error) {
    const status = result.error.status

    /** Aborted fetch calls */
    if (status === 'FETCH_ERROR' && result.error.error.startsWith('AbortError')) {
      return result as never
    }

    /** 404 are occuring during uploads, so we don't flag errors on those */
    if (result.error.status === 404) {
      return result as never
    }

    logger.error('Error loading data: ', result)

    if (typeof status === 'number' && status >= 500) {
      const alert = isOnline()
        ? ({
            message: `Failed to load data: ${JSON.stringify(result.error)}`,
            severity: 'error',
            actions: undefined,
          } satisfies Alert)
        : ({ message: 'Offline', severity: 'warning', actions: undefined } satisfies Alert)
      api.dispatch(notificationsSlice.actions.setAlert(alert))
    }
  }

  /** @note these must be declared in Access-Control-Expose-Headers, see tuna-api.ts */
  const eTag = result.meta?.response?.headers.get('etag') ?? null
  const resumeKey = result.meta?.response?.headers.get('x-resume-key') ?? null
  return {
    ...result,
    meta: result.meta && ({ ...result.meta, eTag, resumeKey } as never),
  }
}

export default baseQueryWithReauth
