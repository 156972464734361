import type { EntityOrElement } from '@tunasong/schemas'
import { useContext } from 'react'
import PluginContext from './plugin-context.js'

export const usePlugin = (elementOrType?: string | EntityOrElement | null) => {
  const repo = useContext(PluginContext)

  const type = typeof elementOrType === 'string' ? elementOrType : elementOrType?.type
  return type ? repo?.getPlugin(type) : undefined
}
