export const releases = [
  { version: '0.4.0', entityId: '95ws3h6s3gov6bbqaemomk4vs' },
  { version: '0.5.0', entityId: '23wsp40vamzq0gkzeyka6zg3l' },
  { version: '0.6.0', entityId: 'b90oy0hmad20vshx7msol6591' },
  { version: '0.6.1', entityId: '896o7olq2ozaz871iud1f58h0' },
  { version: '0.7.0', entityId: '9g05gmqhrjjoelyg31gyjrtn1' },
  { version: '0.8.0', entityId: '5z3tpiz6b2qmdnhl6wio9h355' },
  { version: '0.9.0', entityId: '2mswirvu6bthu1i2ac0hrmzcu' },
  { version: '0.10.0', entityId: '6cx7szzcksgg1xini116tn4bn' },
  { version: '0.11.0', entityId: 'cps42si6kl8eawo9fs4sp5kx4' },
  { version: '0.12.0', entityId: '1f364549lqkg7u1t04h3qdatr' },
  { version: '0.13.0', entityId: 'ak4c4h14gb2vrd1i9vbor586h' },
]
