/**
 * Global configuration
 *
 * Configure per package
 */

import { hotkeySchema, maturitySchema } from '@tunasong/schemas'
import { z } from 'zod'
import packageJSON from '../package.json' with { type: 'json' }
import type { TFeature } from './features.js'
import type { AppManifest } from './manifest.types.js'
import { releases } from './releases.js'

const projectName = 'tunasong'

/** @todo these should be in the plugins and "injected" into the manifest (e.g., during build?) */
export const midiPluginSchema = z
  .object({
    midi: z.boolean().describe('MIDI support').default(false),
    btMidi: z.boolean().describe('Bluetooth MIDI support (experimental)').default(false),
    btMidiAutoConnect: z.boolean().describe('Auto-connect to paired Bluetooth MIDI devices').default(false),
  })
  .optional()

export const chordPlayerPluginSchema = z
  .object({
    play: z.boolean().describe('Play chords on selection').default(false),
  })
  .optional()

export const chordPluginSchema = z
  .object({
    showTranspose: z.boolean().describe('Show transposition with the chord, e.g., +2').default(true),
  })
  .optional()

export const userConfigSchema = z.object({
  featureMaturity: maturitySchema.optional().default('stable'),
  theme: z.enum(['light', 'dark', 'system']).optional().default('system'),
  /** Show content in columns when the horizontal space allows it */
  showContentColumns: z.boolean().describe('Show content in columns in Performance layouts').optional().default(false),
  /** Favorites - entity IDs of the user's favorites */
  favorites: z.array(z.string()).optional(),
  /** AI Configuration */
  ai: z.record(z.unknown()).optional(),
  /** The keyboard shortcuts / hotkeys for the user. Format handled by the useHotkeys hook */
  hotkeys: z.array(hotkeySchema).default([
    { hotkey: 'opt+up', label: 'Block: Move Up', commandId: 'block-move-up' },
    { hotkey: 'opt+down', label: 'Block: Move Down', commandId: 'block-move-down' },
    { hotkey: 'shift+mod+d', label: 'Block: Duplicate', commandId: 'block-duplicate' },
    { hotkey: 'shift+mod+backspace', label: 'Block: Delete', commandId: 'block-delete' },
    { hotkey: 'mod+b', label: 'Format: Bold', commandId: 'format-bold' },
    { hotkey: 'mod+i', label: 'Format: Italic', commandId: 'format-italic' },
  ]),
  musicPreferences: z
    .object({
      transpose: z.number().optional(),
      noteFormat: z.enum(['standard', 'norwegian']).optional(), // Norwegian uses H instead of B
    })
    .optional(),
  plugins: z.lazy(() =>
    z
      .object({
        // The key is the plugin key / id
        midi: midiPluginSchema,
        'chord-player': chordPlayerPluginSchema,
        chord: chordPluginSchema,
      })
      .passthrough()
  ),
})

/** Manifest starts here */
const manifest = {
  name: projectName,
  version: packageJSON.version,

  releases,

  features: ['test-feature'],

  entityTypeMimeExtensions: {
    // Entity / plugin type to mimeType
    // We keep this here to avoid having to instantiate all plugins to extract these.
    audio: { 'audio/*': ['.mp3', '.wav', '.ogg', '.m4a', '.aac', '.mp4', '.webm', '.flac'] },
    image: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp'] },
    pdf: { 'application/pdf': ['.pdf'] },
    page: { 'text/markdown': ['.md'] },
    calendar: { 'text/calendar': ['.ical'] },
    evernote: { 'application/xml': ['.enex'] },
    musicxml: {
      'application/vnd.recordare.musicxml+xml': ['.musicxml'],
      'application/vnd.recordare.musicxml': ['.mxl'],
    },
    video: {
      'video/*': [
        '.mp4',
        '.webm',
        '.ogg',
        '.ogv',
        '.mov',
        '.avi',
        '.wmv',
        '.flv',
        '.m4v',
        '.mpg',
        '.mpeg',
        '.3gp',
        '.3g2',
      ],
    },
  },

  // The host (i.e., hostname:port). Either a string (exact match) or a Regexp to match the host.
  // These will be validated with custom lambdas proxies for login / logout.
  appHosts: [
    'tunasong.com',
    /^.*\.preview\.tunasong\.com$/,
    'localhost:8000',
    // nGrok
    'directly-honest-donkey.ngrok-free.app',
  ],

  /** This config should seldom be changed */
  tables: {
    entities: `${projectName}_entities`,
    entityUpdates: `${projectName}_entities_updates`,
    edges: `${projectName}_entities_graph`,
  },
  schemas: {},

  search: {
    createEmbeddingsForTypes: ['page', 'song'],
    /** don't index these types */
    skipIndexForTypes: [
      'trackerdata',
      'profile',
      'usersettings',
      'audiotrack',
      'midi',
      'pollresponse',
      'entitylink',
      'link',
    ],
    /** Keep these in search, but exclude from some views */
    excludeFromDefault: ['comment', 'audiocomment', 'profile', 'calendarevent'],
    excludeFromRecent: ['comment', 'audiocomment', 'profile', 'calendar', 'note', 'image'],
  },
  /** entity types where we want push to clients on change */
  push: {
    entityTypes: ['comment', 'audiocomment', 'audio', 'page', 'song', 'calendarevent', 'folder'],
  },

  /**
   * Config is typically implemented by the plugin with the key
   * @todo why is this in the manifest? There was a good reason, now forgotten.
   * It makes sense to have config values here, but the schema?
   * Ideally, we define these in the plugin, and the manifest is just a list of keys.
   */
  userConfig: userConfigSchema,
} satisfies AppManifest<TFeature>

export type UserConfig = z.infer<typeof userConfigSchema>

export const defaultUserConfig = manifest.userConfig.parse({ plugins: {} })

export default manifest
