/** Storage object. Used for S3 backed storage, where the Urls are signed */
import type { Entity, EntityType, S3Storage, Storage } from '@tunasong/schemas'

/** Public storage, i.e., a public link on the internet */
export interface PublicStorage extends Storage {}

export type StoredObject<T extends Entity = Entity> = T & {
  name?: string
  type: EntityType

  /** Storage specification */
  storage?: Storage
}

export interface CompleteStoredObject extends StoredObject {
  storage: S3Storage
}

export function isStoredObject(obj: unknown): obj is CompleteStoredObject {
  return Boolean(obj && (obj as CompleteStoredObject)?.storage?.filename && typeof obj === 'object' && 'id' in obj)
}
export interface S3Object extends StoredObject {
  readonly storage: S3Storage
}
