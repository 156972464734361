import type { FilterFunc, ListMenuOptions, ShareItem, SortOptions } from '@tunasong/models'
import { captureException, entitySort, hasParent, includeElement, logger } from '@tunasong/models'
import {
  activitiesApi,
  entitiesApi,
  features,
  prioritySort,
  profilesApi,
  skipToken,
  storageApi,
  useSelector,
  useStore,
  useThunkDispatch,
  useWithIncremental,
} from '@tunasong/redux'
import type {
  ACL,
  Edge,
  EdgeType,
  ElementType,
  Entity,
  EntityOrElement,
  EntityType,
  Persisted,
  Profile,
} from '@tunasong/schemas'
import { Mutex } from 'async-mutex'
import { useCallback, useEffect, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
import type {
  GraphHooks,
  GraphHooksMutationStatus,
  IncrementalOptions,
  UseChildren,
  UseChildrenIncProps,
} from '../graph-types.js'
import { childFilter } from '../util.js'
/** Hooks */
const useEntity = <T extends Entity = Entity>(id?: string | null) => {
  const skip = !id || id === 'ROOT'

  const {
    currentData: entity,
    data: previousEntity,
    ...restProps
  } = entitiesApi.useLoadEntityQuery(skip ? skipToken : { id }, {})

  return {
    entity: id ? (entity as Persisted<T>) : undefined,
    previousEntity: previousEntity as Persisted<T>,
    ...restProps,
  }
}

/** Load all entities for the user */
const useEntities = <T extends Entity = Entity>({
  root = true,
  filter,
  showTrash = false,
  owned = true,
  pollingInterval,
}: {
  /** Use only root entities, i.e., entities that have no parents */
  root?: boolean
  /** Load only partial */
  partial?: boolean
  showTrash?: boolean
  filter?: ElementType | FilterFunc
  /** Load owned entities */
  owned?: boolean
  // polling interval (in ms)
  pollingInterval?: number
} = {}) => {
  /** We use the hook to load the data only, and work from the entities store. The API will sync.  */
  const { currentData: entities, ...restProps } = entitiesApi.useLoadAllEntitiesQuery(
    {},
    { skip: !owned, pollingInterval }
  )

  const allEntities = (entities ?? [])
    .filter(e => includeElement(e, filter))
    .filter(e => (root ? !hasParent(e) : true))
    .filter(e => (showTrash ? true : Boolean(!e.trash)))
    /** Unique */
    .filter((v, i, a) => a.findIndex(v2 => v.id === v2.id) === i)

  return { entities: allEntities as Persisted<T>[], ...restProps }
}

/**
 * Update an entity in Redux and update the server (debounced).
 * We accept only one outstanding request per entity hook.
 */

// We use a mutex to ensure only one update is running at a time for a single entity. We do this
// to avoid eTag problems.
const entityMutexes = new Map<string, Mutex>()

const useEntityUpdate = <T extends Entity = Entity>({
  debounceDelay = 0,
  upsert = false,
}: {
  /** Debounce delay before sending changes to the API in MS. @default 0 */
  debounceDelay?: number
  /** Use upsert, i.e., create the entity if it does not exist */
  upsert?: boolean
} = {}) => {
  const [updateMutation] = entitiesApi.useUpdateEntityMutation()

  const timoutId = useRef<number | NodeJS.Timeout | null>(null)
  const updates = useRef<(Partial<Entity> & { id: string }) | null>(null)

  /** Update function */
  const update = (entityId: string, partialEntity: Partial<T>) => {
    if (!entityId) {
      logger.warn('No entity id provided to useEntityUpdate')
      return
    }

    let entityMutex = entityMutexes.get(entityId)
    if (!entityMutex) {
      entityMutex = new Mutex()
      entityMutexes.set(entityId, entityMutex)
    }

    if (timoutId.current) {
      clearTimeout(timoutId.current)
    }

    /** Accumulate changes before we apply them */
    updates.current = { id: entityId, ...updates.current, ...partialEntity }

    timoutId.current = setTimeout(() => {
      // Ensure we only run one update at a time to avoid eTag issues
      entityMutex.runExclusive(async () => {
        const accumulatedUpdates = updates.current
        /** Since we timeout and runExclusive, there may be no updates here. That's OK */
        if (!accumulatedUpdates) {
          return
        }
        /** Reset the updates object, so we can start to accumulate new changes */
        updates.current = null
        /**
         * @note we don't update Redux here because it is delayed and
         * we want to avoid overwriting Redux with stale data.
         * However, the Service Worker will update the Redux cache,
         * so it's a bit unclear what the best approach is here.
         */

        // const eTag = getState().entities.eTags[entityId]
        const eTag = null
        // Await the mutation since we're running in a mutex exclusive block
        await updateMutation({ id: entityId, partialEntity: accumulatedUpdates, eTag, upsert }).unwrap()
      })
    }, debounceDelay)
  }

  return update
}

const useEntityDelete = () => {
  const [deleteEntity, deleteEntityResult] = entitiesApi.useDeleteEntityMutation()
  const delEntity = async ({ entity }: { entity: Persisted<Entity> }) => {
    invariant(entity, 'Entity is required')
    return deleteEntity({ entity }).unwrap()
  }
  return [delEntity, deleteEntityResult] as [
    (props: { entity: Persisted<Entity> }) => Promise<void>,
    GraphHooksMutationStatus,
  ]
}

const useEntityTrash = (entity?: Persisted<Entity>) => {
  const update = useEntityUpdate({ debounceDelay: 0 })
  /** @todo for some reason the app crashes without useCallback here - suspect react-compiler */
  const trash = useCallback(() => {
    invariant(entity?.id, 'Entity is required')

    update(entity.id, { trash: true })
  }, [entity?.id, update])

  return trash
}

const useEntityCreate = () => {
  const [createEntity, createEntityStatus] = entitiesApi.useCreateEntityMutation()
  const create = async <T extends Entity = Entity>({
    entity,
    parent,
    isPrivate,
  }: {
    entity: T
    parent: Persisted<Entity> | null
    isPrivate?: boolean
  }) => createEntity({ entity, parent, isPrivate }).unwrap() as Promise<Persisted<T>>
  return { createEntity: create, createEntityStatus }
}

const useEntityClone = () => {
  const [cloneEntity, cloneEntityStatus] = entitiesApi.useCloneEntityMutation()
  const clone = async <T extends Persisted<Entity> = Persisted<Entity>>({
    entity,
    name,
  }: {
    entity: T
    name: string
  }) => cloneEntity({ entityId: entity.id, name }).unwrap() as Promise<Persisted<T>>
  return { cloneEntity: clone, cloneEntityStatus }
}

const useEntityShare = () => {
  const [shareEntity, shareEntityStatus] = entitiesApi.useShareMutation()
  const share = async <T extends Persisted<ShareItem>>({ entity, acls }: { entity: T; acls: ACL[] }) =>
    shareEntity({ item: entity, acls }).unwrap() as Promise<T>
  return { shareEntity: share, shareEntityStatus }
}

const useEntityPublicLoad = ({ id }: { id?: string }) => {
  const result = entitiesApi.useLoadEntityPublicQuery({ id: id ?? '' }, { skip: !id })
  return { ...result, ...result.currentData }
}

const useEntityChildren: GraphHooks['useEntityChildren'] = <T extends Entity = Entity>(
  {
    parentId = null,
    defaultSort = false,
    filter,
    includeSys = true,
    skip: providedSkip,
    pollingInterval,
  }: {
    parentId?: string | null
    filter?: EntityType | FilterFunc
    // skip the query
    skip?: boolean
    defaultSort?: boolean
    includeSys?: boolean
    // Poll every x ms
    pollingInterval?: number
  } = {
    includeSys: true,
  }
) => {
  const skip = providedSkip || Boolean(!parentId)
  const {
    currentData = [],
    isSuccess,
    ...restProps
  } = entitiesApi.useLoadChildEntitiesQuery(
    { parentId: parentId ?? '' },
    {
      skip,
      pollingInterval,
    }
  )

  /** Make sure we don't re-render infinitely */
  const sortOptions: SortOptions | undefined = defaultSort
    ? {
        sortBy: 'updatedAt',
        order: 'desc',
      }
    : undefined

  const filteredEntities = currentData?.filter(childFilter({ parentId, filter, includeSys }))
  const entities =
    sortOptions && filteredEntities ? entitySort({ entities: filteredEntities, sortOptions }) : filteredEntities

  const hasLoaded = Boolean(isSuccess && entities)

  return {
    entities: entities as Persisted<T>[],

    /** useMemo will happen the next render, so isSuccess will be set before we have data in entities */
    isSuccess: hasLoaded,

    hasLoaded,
    ...restProps,
  }
}

/** Get the child entity matching the filter. If there are > 1 children matching the critiera this hook will throw */
const useEntityChild = <T extends Entity>({ parentId, filter }: UseChildren) => {
  const { entities: children, isSuccess, ...restProps } = useEntityChildren<T>({ parentId, filter })
  try {
    if (children.length > 1) {
      logger.error(`useChild found more than one child matching the filter:`, { parentId, children })
      throw new Error(`useChild found more than one child matching the filter: ${JSON.stringify(filter)}`)
    }
    /** If we have loaded and found no children, return null */
  } catch (e) {
    captureException(e)
  }
  const child = isSuccess && children.length === 0 ? undefined : children[0]

  return { child, isSuccess, ...restProps }
}

const useEntityChildrenIncremental = <T extends Entity = Entity>({
  filter,
  includeSys = true,
  pageSize = 25,
  startKey,
  parentId,
}: UseChildrenIncProps) => {
  const [load, loadResult] = entitiesApi.useLazyLoadChildEntitiesPartialQuery()

  const onLoad = async ({ limit, startKey }: { limit: number; startKey?: string }) => {
    invariant(parentId, 'parentId is required')
    const data = await load({
      parentId,
      startKey,
      limit,
    }).unwrap()

    return {
      ...data,
      entities: data.entities.filter(childFilter({ parentId, filter, includeSys })) as unknown as T[],
    }
  }

  /** Handle incremental load hook */
  const { getNext, resetIncrementalState, ...incremental } = useWithIncremental<T>({
    onLoad: parentId ? onLoad : null,
    pageSize,
    startKey,
  })
  // Initial load. We need to track the parentId for the load to ensure we load only once.
  const currentParentId = useRef<string | null>(null)
  useEffect(() => {
    if (parentId === currentParentId.current) {
      return
    }
    currentParentId.current = parentId ?? null
    if (parentId) {
      resetIncrementalState()
      getNext()
    }
  }, [getNext, parentId, resetIncrementalState])

  const { isLoading, isFetching, isError, error } = loadResult

  return { ...incremental, resetIncrementalState, getNext, isLoading, isFetching, isError, error }
}

/** Hook to allow incremental loading of entities */
const useEntitiesInc = <T extends Entity = Entity>(props: IncrementalOptions) => {
  const { pageSize, startKey, ...restProps } = props

  const [load, loadResult] = entitiesApi.useLazyLoadAllEntitiesPartialQuery()

  const onLoad = async ({ limit, startKey }: { limit: number; startKey?: string }) => {
    const data = await load({ ...restProps, limit, startKey }).unwrap()

    return { ...data, entities: data.entities as unknown as T[] }
  }

  /** Handle incremental load hook */
  const incremental = useWithIncremental({
    onLoad,
    pageSize,
    startKey,
  })

  return {
    ...incremental,
    entities: incremental.entities as T[],
    isLoading: loadResult.isLoading,
    isFetching: loadResult.isFetching,
    isError: loadResult.isError,
    error: loadResult.error,
  }
}

/** Load entity, partially loading children if those have not been loaded */
export const useEntitiesById = <T extends Entity = Entity>(ids: string[] = []) => {
  const sortedIds = ids
    .filter(id => id !== 'ROOT')
    .filter(Boolean)
    // Sort to ensure that we don't refetch the same entities in a different order
    .sort()
  const {
    currentData: entities,
    data: existingEntities = [],
    ...restProps
  } = entitiesApi.useLoadEntitiesQuery({ ids: sortedIds }, { skip: sortedIds.length === 0 })

  /** Some components require the entities in the specified order */
  const orderedEntities = ids
    .map(id => entities?.find(entity => entity.id === id))
    .filter(Boolean) as unknown as Persisted<T>[]

  return { entities: orderedEntities, existingEntities: existingEntities as Persisted<T>[], ...restProps }
}

/**
 * @todo refactor this into the hooks
 * @deprecated
 */
export const useEntityGraph = ({
  debounceDelay = 1000,
  upsert = false,
}: { debounceDelay?: number; upsert?: boolean } = {}) => {
  const dispatch = useThunkDispatch()
  const updateEntity = useEntityUpdate({ debounceDelay, upsert })
  const getEntitiesById = useEntitiesById()

  const [updateProfileMutation] = profilesApi.useUpdateProfileMutation()
  const updateProfile = useCallback(
    async ({ id, profile }: { id: string; profile: Partial<Profile> }): Promise<Persisted<Profile>> =>
      updateProfileMutation({
        id,
        profile,
      }).unwrap(),
    [updateProfileMutation]
  )

  const [loadEntity] = entitiesApi.useLazyLoadEntityQuery()
  const [createEntity, createEntityResult] = entitiesApi.useCreateEntityMutation()
  const [loadFile] = storageApi.useLazyGetAuthorizedStorageFileQuery()

  /**
   * @see https://redux-toolkit.js.org/rtk-query/usage/prefetching

   */
  const prefetchEntity = entitiesApi.usePrefetch('loadEntity')
  const prefetchEntityById = useCallback(
    async (id?: string, ifOlderThanSeconds = 60) => {
      const skip = !id || id === 'ROOT'
      if (skip) {
        return
      }
      prefetchEntity({ id }, { ifOlderThan: ifOlderThanSeconds })
    },
    [prefetchEntity]
  )

  /**
   * Load the entity and its storage file if it is a yDoc.
   */
  const getEntityById = useCallback(
    async (id?: string) => {
      const skip = !id || id === 'ROOT'
      if (skip) {
        return
      }
      // @note important to use `true` as the second argument to avoid always triggering a network request if we have the data in the cache
      const loadResult = await loadEntity({ id }, true)
      if (loadResult.data) {
        const { storage, storageUrls } = loadResult.data
        if (storage?.docType === 'yDoc' && storageUrls) {
          loadFile({ storageUrls }, true)
        }
      }
      return loadResult
    },
    [loadEntity, loadFile]
  )

  const getBacklinks = useCallback(
    async (entityId: string): Promise<Edge[]> =>
      dispatch(entitiesApi.endpoints.loadEntityBacklinks.initiate({ id: entityId })).unwrap(),
    [dispatch]
  )

  return {
    createEntity,
    isCreatingEntity: createEntityResult.isLoading,
    hasCreatedEntity: createEntityResult.isSuccess,
    createEntityResult,
    updateEntity,
    getEntitiesById,
    prefetchEntityById,
    getEntityById,
    getBacklinks,
    updateProfile,
  }
}

/** Load the entities referred by the edges */
function useEdgeEntities<T extends Entity = Entity, S extends Entity = Entity>({
  sourceId,
  targetId,
  relation,
}: {
  sourceId?: string
  targetId?: string
  relation?: EdgeType
}) {
  const { edges, isLoading: isLoadingEdges } = useEdges({ sourceId, targetId, relation })

  const entityIds = edges.flatMap(e => [e.source, e.target])

  const { entities, isLoading: isLoadingEntities, isSuccess } = useEntitiesById(entityIds)

  const edgeEntities = isSuccess
    ? edges
        .map(edge => ({
          ...edge,
          source: entities.find(e => edge.source === e.id) as Persisted<T>,
          target: entities.find(e => edge.target === e.id) as Persisted<S>,
        }))
        .filter(e => e.source && e.target)
        .sort((a, b) => (a.target.updatedAt > b.target.updatedAt ? -1 : 1))
    : []

  const [createEdge, createEdgeStatus] = entitiesApi.useCreateEntityEdgeMutation()
  const [deleteEdgeMutation] = entitiesApi.useDeleteEntityEdgeMutation()

  const create = (targetId: string) => {
    invariant(sourceId && relation, 'Source entity and relation is required')
    return createEdge({ edge: { source: sourceId, target: targetId, relation } }).unwrap()
  }
  const deleteEdge = (targetId: string) => {
    invariant(sourceId && relation, 'Source entity and relation is required')
    return deleteEdgeMutation({ source: sourceId, target: targetId, relation })
  }

  return {
    edgeEntities,
    createEdge: create,
    deleteEdge,
    hasLoaded: isSuccess || entityIds.length === 0,
    isCreating: createEdgeStatus.isLoading,
    isLoading: isLoadingEdges || isLoadingEntities,
  }
}

export const useEdges = ({
  sourceId,
  targetId,
  relation,
}: {
  sourceId?: string
  targetId?: string
  relation?: EdgeType
} = {}) => {
  const {
    currentData = [],
    isSuccess,
    isLoading,
  } = entitiesApi.useLoadEntityEdgesQuery(sourceId ? { source: sourceId, target: targetId } : skipToken)
  const edges = relation ? currentData.filter(link => link.relation === relation) : currentData

  const [createEdgeMutation, createStatus] = entitiesApi.useCreateEntityEdgeMutation()
  const [deleteEdgeMutation] = entitiesApi.useDeleteEntityEdgeMutation()

  const createEdge = (targetId: string, edgeType: EdgeType) => {
    if (!sourceId) {
      throw new Error('Source entity is required')
    }
    return createEdgeMutation({
      edge: {
        source: sourceId,
        target: targetId,
        relation: edgeType,
      },
    })
  }

  const deleteEdge = (edge: Edge) => {
    invariant(sourceId && targetId, 'Source and target entity is required')
    return deleteEdgeMutation(edge)
  }

  return { edges, hasLoaded: isSuccess, isLoading, isCreating: createStatus.isLoading, createEdge, deleteEdge }
}

const useEntityEdgesByIds = ({
  sources,
  target,
  relation,
}: {
  sources: string[]
  target?: string
  relation?: EdgeType
}) => {
  const { currentData = [], ...restProps } = entitiesApi.useLoadEntityEdgesByIdsQuery(
    sources.length === 0 ? skipToken : { sources, target, relation }
  )
  return { edges: currentData, ...restProps }
}

const useEntityBacklinks = ({ entityId, relation }: { entityId?: string; relation?: EdgeType }) => {
  const { currentData = [], ...restProps } = entitiesApi.useLoadEntityBacklinksQuery(
    !entityId ? skipToken : { id: entityId }
  )

  const backlinks = relation ? currentData.filter(e => e.relation === relation) : currentData

  return { backlinks, ...restProps }
}
const useEntityUtil = () => {
  const dispatch = useThunkDispatch()
  const updateEntity = useEntityUpdate()
  const [] = useState()
  const updateListMenuOptions = useCallback(
    (entity: Persisted<Entity>, listOptions: ListMenuOptions) => {
      const options = {
        ...entity.options,
        list: listOptions,
      }

      updateEntity(entity.id, { options })
    },
    [updateEntity]
  )

  const reorderChildren = useCallback(
    (entity: Persisted<Entity>, children: Persisted<Entity>[]) => {
      updateEntity(entity.id, {
        options: {
          ...entity.options,
          sort: {
            ...entity.options?.sort,
            type: 'order', // use order when reordering
            elementOrder: children.map(sn => sn.id),
          },
        },
      })
    },
    [updateEntity]
  )

  const moveEntity = async ({ entity, newParent }: { entity: Persisted<Entity>; newParent: Persisted<Entity> }) =>
    dispatch(
      features.entities.thunks.addChild({
        parentId: newParent.id,
        childId: entity.id,
      })
    )

  return {
    moveEntity,
    updateListMenuOptions,
    reorderChildren,
  }
}

const useTags = (entity?: EntityOrElement) => {
  const allTags = entity?.tags ?? []
  const systemTags = new Set(allTags.filter(tag => tag.startsWith('system:')))
  const tags = new Set(allTags.filter(tag => !tag.startsWith('system:')))

  const updateEntity = useEntityUpdate()
  const hasTag = (tag: string) => tags.has(tag) || systemTags.has(tag)
  const addTag = (tag: string) => {
    if (!entity?.id || hasTag(tag)) {
      return
    }
    updateEntity(entity.id, { tags: [...tags, tag] })
  }
  const removeTag = (tag: string) => {
    if (!entity?.id || !hasTag(tag)) {
      return
    }
    updateEntity(entity.id, { tags: [...tags].filter(t => t !== tag) })
  }

  return { tags, systemTags, addTag, removeTag, hasTag }
}

const useActivities = (entityId: string) => {
  const { currentData: activities } = activitiesApi.useLoadActivitiesForEntityQuery({ entityId })
  /** Sort by createdAt */
  return prioritySort(activities ?? [], 'createdAt')
}

const useAllActivities = () => {
  const { currentData: activities = [], isLoading, isFetching } = activitiesApi.useLoadActivitiesQuery()

  const sorted = prioritySort(activities, 'createdAt')

  return { activities: sorted, isLoading: isLoading || isFetching }
}

/** Get the profile for userId id. If not specified, get the profile for the currently logged in user. */

const assistantProfile: Persisted<Profile> = {
  id: 'assistant',
  acls: [],
  userId: 'assistant',
  name: 'Tunabrain',
  // picture: logo,
  email: 'brain@tunasong.com',
  config: {},
  type: 'profile',
  usage: {},

  updatedAt: new Date().toISOString(),
  createdAt: new Date().toISOString(),
}
const useProfile = (id?: string) => {
  const currentUserId = useSelector(state => state.user?.userId)

  const queryId = id === 'PUBLIC' ? undefined : (id ?? currentUserId)

  const isAssistant = id === 'assistant'

  /** Trigger load of current user, once. Once the loggedIn user is in Redux, the skip guard will prevent performance degradation */
  const { currentData: userProfile, ...restProps } = profilesApi.useLoadProfileQuery(
    { emailOrUserId: queryId ?? '' },
    { skip: !queryId || isAssistant }
  )

  const profile = isAssistant ? assistantProfile : userProfile

  return { profile: profile as Persisted<Profile> | undefined, ...restProps }
}

const useProfiles = ({
  userIds = [],
  includeAssistant = true,
}: {
  userIds?: string[]
  includeAssistant?: boolean
} = {}) => {
  const { currentData: rawProfiles, ...restProps } = profilesApi.useLoadProfilesQuery(
    { userIds },
    { skip: userIds.length === 0 }
  )

  const profiles = [includeAssistant ? assistantProfile : null, ...(rawProfiles ?? [])].filter(Boolean)

  const me = useSelector(state => state.user.userId)

  const getName = useCallback(
    (id: string) => {
      if (!id) {
        return 'Unknown'
      }
      if (id === me) {
        return 'You'
      }
      const profile = profiles.find(profile => profile?.id === id)
      return profile ? profile.name || profile.email : '...'
    },
    [me, profiles]
  )

  return {
    ...restProps,
    profiles,
    getName,
  }
}

const useCollaborators = (entityId?: string) => {
  const userId = useSelector(state => state.user.userId)
  const entitySelector = entityId ? entitiesApi.endpoints.loadEntity.select({ id: entityId }) : null
  const entity = useSelector(state => (entitySelector ? entitySelector(state).data : null))

  const users = [userId, ...(entity?.acls ?? []).map(a => a.principal)].filter(Boolean) as string[]

  const { currentData: loadedProfiles = [] } = profilesApi.useLoadProfilesQuery({ userIds: users })

  const profiles = [
    assistantProfile,
    ...loadedProfiles.map(p => ({ ...p, type: 'profile' })).filter(p => (entity ? users.includes(p.userId) : true)),
  ]

  /** If entity is known, we use the ACL information. Otherwise we'll use the profiles in our store */
  return profiles
}

/** Load parent entities to ensure that e.g., breadcrumbs work properly */
const useAncestors = (entity?: Pick<Entity, 'parentId'>) => {
  const [ancestors, setAncestors] = useState<Record<string, Persisted<Entity>>>({})

  const [loadEntity] = useLoadEntity()

  const parentId = entity?.parentId

  const loadParents = useCallback(
    async (entityId: string) => {
      invariant(entityId, 'entityId must be specified')
      let parentId: string | undefined = entityId
      const parents = []
      while (parentId && parentId !== 'ROOT') {
        const e: Persisted<Entity> = await loadEntity({ id: parentId }, true).unwrap()
        parents.push(e)
        parentId = e.parentId
      }
      return parents
    },
    [loadEntity]
  )

  useEffect(() => {
    if (!parentId) {
      return
    }
    loadParents(parentId).then(ancestors => {
      const ancestorsMap: Record<string, Persisted<Entity>> = {}
      for (const ancestor of ancestors) {
        ancestorsMap[ancestor.id] = ancestor
      }
      setAncestors(ancestorsMap)
    })
  }, [loadParents, parentId])

  return ancestors
}

const useEntityEdgeCreate = entitiesApi.useCreateEntityEdgeMutation
const useEntityEdgeDelete = entitiesApi.useDeleteEntityEdgeMutation
// Load handlers
const useLoadChildEntities = entitiesApi.useLazyLoadChildEntitiesQuery
const useLoadChildEntitiesPartial = entitiesApi.useLazyLoadChildEntitiesPartialQuery
const useLoadEntity = entitiesApi.useLazyLoadEntityQuery

/** @todo thse should be refactored */
const useGetEntityChildren = () => {
  const { getState } = useStore()
  return ({ parentId, type }: { parentId: string; type?: EntityType }) =>
    entitiesApi.endpoints.loadChildEntities.select({ parentId, type })(getState())
}
const useGetEntity = () => {
  const { getState } = useStore()
  return (id: string) => entitiesApi.endpoints.loadEntity.select({ id })(getState()).data
}

const useInvite = entitiesApi.useInviteMutation

const useEntityTimeTravel = entitiesApi.useTimeTravelQuery
const useEntityTimeTravelRestore = entitiesApi.useEntityTimeTravelRestoreMutation
const useEntityRestoreVersion = entitiesApi.useRestoreEntityVersionMutation
const useLoadEntityHistory = entitiesApi.useLazyLoadEntityHistoryQuery
const useEntityTransfer = entitiesApi.useTransferEntityMutation
const useEntitiesByEdgeByIds = entitiesApi.useLoadEdgeEntitiesByIdsQuery

/** @todo make this of shape GraphHooks */
const createRtkReduxHooks = () => ({
  useEntity,
  useEntityPublicLoad,
  useEntityUpdate,
  useEntityCreate,
  useEntityClone,
  useEntityTrash,
  useEntityDelete,
  useEntityShare,
  useEntities,
  useEntitiesInc,
  useEntityChild,
  useEntityChildren,
  useEntityChildrenIncremental,
  useEntitiesById,

  useEntityGraph,
  useEntityUtil,
  useEdgeEntities,
  useEntityTimeTravel,
  useEntityTimeTravelRestore,
  useEntityRestoreVersion,
  useLoadEntityHistory,
  useEntityTransfer,
  /** Edges */
  useEntityEdgeCreate,
  useEntityEdgeDelete,
  useEdges,
  useEntityEdgesByIds,
  useEntityBacklinks,
  useEntitiesByEdgeByIds,
  /** Misc */
  useLoadChildEntities,
  useLoadChildEntitiesPartial,
  useLoadEntity,
  useTags,
  useActivities,
  useAllActivities,
  useProfile,
  useProfiles,
  useCollaborators,
  useAncestors,
  useInvite,
  /** @todo refactor these */
  useGetEntityChildren,
  useGetEntity,
})

export default createRtkReduxHooks()
