import { z } from 'zod'

export const searchQuerySchema = z.object({
  _source: z.unknown().optional(),
  sort: z.array(z.unknown()).optional(),
  collapse: z
    .object({
      field: z.string(),
      inner_hits: z.object({
        name: z.string(),
        size: z.number(),
        _source: z.record(z.string(), z.unknown()),
      }),
    })
    .optional(),
  query: z.object({
    function_score: z.unknown().optional(),
    query_string: z.unknown().optional(),
    term: z.unknown().optional(),
    terms: z.unknown().optional(),
    bool: z
      .object({
        filter: z.union([z.unknown(), z.array(z.unknown())]).optional(),
        must: z.union([z.unknown(), z.array(z.unknown())]).optional(),
        should: z.union([z.unknown(), z.array(z.unknown())]).optional(),
        must_not: z.union([z.unknown(), z.array(z.unknown())]).optional(),
        bool: z.unknown(),
      })
      .optional(),
    knn: z
      .object({
        embedding: z.object({
          vector: z.number().array(),
          k: z.number(),
        }),
      })
      .optional(),
    filter: z.array(z.unknown()).optional(),
  }),
})

export type SearchQuery = z.infer<typeof searchQuerySchema>

export const searchRequestSchema = z.object({
  /** Our custom request parameters */
  includePublic: z.boolean().optional(),
  /** OpenSearch */
  index: z.string(),
  size: z.number().optional(),
  from: z.number().optional(),
  _source_excludes: z.array(z.string()).optional(),
  body: searchQuerySchema,
})
export type SearchRequest = z.infer<typeof searchRequestSchema>

export const searchFilterSchema = z.object({
  tags: z.array(z.string()).optional(),
  types: z.array(z.string()).optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
})

export type SearchFilter = z.infer<typeof searchFilterSchema>

export const searchHitSchema = z.object({
  _index: z.string(),
  _type: z.string(),
  _id: z.string(),
  _score: z.number(),
  _source: z.record(z.string(), z.unknown()),
})

export type SearchHit<T> = z.infer<typeof searchHitSchema> & { _source: T }

export const searchResultSchema = z.object({
  body: z.object({
    took: z.number(),
    timed_out: z.boolean(),
    _shards: z.unknown(),
    hits: z.object({
      max_score: z.number().nullable(),
      hits: z.array(searchHitSchema),
    }),
  }),
})

export type SearchResult<T> = z.infer<typeof searchResultSchema> & { body: { hits: { hits: SearchHit<T>[] } } }
