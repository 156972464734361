/** Get the shared type and initialize it with children */

import { slateNodesToInsertDelta } from '@slate-yjs/core'
import type { TunaDecendant } from '@tunasong/schemas'
import { fromUint8Array } from 'js-base64'
import invariant from 'tiny-invariant'
import * as Y from 'yjs'
import { getSharedTypeV2 } from './get-shared-type.js'

export const createCollaborativeDoc = ({ id, nodes }: { id?: string; nodes: TunaDecendant[] }) => {
  const doc = new Y.Doc({ guid: id })

  const sharedType = getSharedTypeV2(doc)
  invariant(sharedType.length === 0, 'sharedType must be be empty')

  sharedType.applyDelta(slateNodesToInsertDelta(nodes))

  // Create a serialized string for the doc
  const yDocAsUpdates = Y.encodeStateAsUpdate(doc)
  const yDoc = fromUint8Array(yDocAsUpdates)

  return { doc, sharedType, yDoc, yDocAsUpdates }
}
