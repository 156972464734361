import { isCollabEntity, logger } from '@tunasong/models'
import { skipToken, storageApi } from '@tunasong/redux'
import type { Entity } from '@tunasong/schemas'
import { Y, getElementValue, getSlateYDocVersion } from '@tunasong/sync-lib'
import { toUint8Array } from 'js-base64'
import { useEffect, useState } from 'react'
import invariant from 'tiny-invariant'

/** Create a collaborative YDoc from the specified entity */
export const useYDoc = <T extends Entity = Entity>(entity?: T) => {
  const isCollab = isCollabEntity(entity)

  // Disallow using this hook with non-collaborative entities
  if (entity && !isCollab) {
    throw new Error(`Entity is not a collaborative entity: ${entity.id}`)
  }

  const { id, yDoc: entityYDoc, storageUrls } = entity ?? {}

  const [doc, setDoc] = useState<Y.Doc | undefined>(undefined)

  const useS3 = Boolean(storageUrls)

  // @deprecated Legacy support for yDoc as string inside the entity
  const entityYDocAsUpdates = entityYDoc ? toUint8Array(entityYDoc) : undefined

  // New support for yDoc in S3 storage
  // Skip if the storageUrls have expired
  const validUrls = storageUrls && storageUrls.expiresAtISODateTime > new Date().toISOString()
  if (entity && !validUrls) {
    logger.warn('Storage URLs are either not set or have expired', { id, storageUrls })
  }

  // @important we must use currentData here to avoid using data from another entity, i.e., that params and data match
  const { currentData: yDocS3, ...restProps } = storageApi.useGetAuthorizedStorageFileQuery(
    validUrls ? { storageUrls } : skipToken
  )

  const docUpdates = useS3 ? yDocS3 : entityYDocAsUpdates

  /** If yDoc is set we apply the changes to the local doc */
  useEffect(() => {
    if (!id || !isCollab || !docUpdates) {
      return
    }

    const applyChanges = (doc: Y.Doc, updates: Uint8Array) => {
      invariant(doc, 'Y.Doc must be provided before applying changes')
      invariant(updates, 'Y updates must be provided')
      Y.applyUpdate(doc, updates)
    }

    /** Has ID changed? */
    if (id === doc?.guid) {
      // Not changed - get the local doc up to date with the updates
      logger.debug('Applying new updates to existing Y.Doc', { id })
      applyChanges(doc, docUpdates)
      return
    }

    // Doc has changed, we need to destroy the old one
    if (doc) {
      doc.destroy()
    }
    const newDoc = new Y.Doc({ guid: id })
    Y.applyUpdate(newDoc, docUpdates)
    setDoc(newDoc)

    logger.debug('Applying initial updates to newly created local Y.Doc', { id })
    applyChanges(newDoc, docUpdates)
  }, [doc, docUpdates, id, isCollab])

  /** We can never return a doc that belongs to another entity than provided to this hook */
  const verifiedIdDoc = doc && doc.guid === id ? doc : undefined

  const value = verifiedIdDoc ? getElementValue(verifiedIdDoc) : null
  const element = value ? ({ ...entity, children: value } as never as T) : null
  const { v1, v2 } = verifiedIdDoc ? getSlateYDocVersion(verifiedIdDoc) : { v1: false, v2: false }

  invariant(!verifiedIdDoc || verifiedIdDoc.guid === id, 'Y.Doc ID does not match entity ID')

  // If verifiedDoc is not set, value and element must be null
  invariant(!value || (value && verifiedIdDoc), 'Value must be undefined if doc is not set')
  invariant(!element || (element && verifiedIdDoc), 'Value must be undefined if doc is not set')

  /** Ensure we never return a doc for the wrong ID */
  return { doc: verifiedIdDoc, element, value, v1, v2, ...restProps }
}
