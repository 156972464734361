import { activitiesApi } from './activities.js'
import { clientApi } from './client-api.js'
import { entitiesApi } from './entities.js'
import { gamesApi } from './games.js'
import { mediaApi } from './media.js'
import { nlpApi } from './nlp.js'
import { profilesApi } from './profiles.js'
import { pushApi } from './push.js'
import { searchApi } from './search.js'
import { slugsApi } from './slugs.js'
import { storageApi } from './storage.js'
import { workflowsApi } from './workflows.js'

export * from './activities.js'
export * from './client-api.js'
export * from './entities.js'
export * from './format-error.js'
export * from './games.js'
export * from './media.js'
export * from './nlp.js'
export * from './profiles.js'
export * from './push.js'
export * from './search.js'
export * from './slugs.js'
export * from './storage.js'
export * from './workflows.js'

export type APIName =
  | 'nlp-api'
  | 'profiles-api'
  | 'activities-api'
  | 'slugs-api'
  | 'storage-api'
  | 'search-api'
  | 'entities-api'
  | 'media-api'
  | 'games-api'
  | 'push-api'
  | 'workflows-api'
  | 'client-api'

export const APIMap = {
  'nlp-api': nlpApi,
  'profiles-api': profilesApi,
  'activities-api': activitiesApi,
  'slugs-api': slugsApi,
  'storage-api': storageApi,
  'search-api': searchApi,
  'entities-api': entitiesApi,
  'media-api': mediaApi,
  'games-api': gamesApi,
  'push-api': pushApi,
  'workflows-api': workflowsApi,
  'client-api': clientApi,
} as const satisfies Record<APIName, unknown>
