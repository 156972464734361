/**
 * Cached graph interface that integrates with the Graph hooks API.
 */

import { useGraphCacheStore } from '@tunasong/graph-cache'
import { type FilterFunc } from '@tunasong/models'
import type { ElementType, Entity, EntityType, Persisted } from '@tunasong/schemas'
import { useEffect } from 'react'
import type { GraphHooks, UseChildren } from '../graph-types.js'
import { childFilter, getLatestEntry } from '../util.js'

export const createCachedGraph = <T extends GraphHooks>(graphHooks: T) => {
  const useEntity = (id?: string | null) => {
    const { entityCache, updateCache } = useGraphCacheStore()
    const status = graphHooks.useEntity(id)

    useEffect(() => {
      if (!(id && status.isSuccess && status.entity)) {
        return
      }
      updateCache(status.entity)
    }, [id, status.entity, status.isSuccess, updateCache])

    return { ...status, entity: getLatestEntry({ id, candidate: status.entity, cache: entityCache }) }
  }

  const useEntityUpdate = (props: { upsert?: boolean; debounceDelay?: number }) => {
    const apiUpdate = graphHooks.useEntityUpdate(props)
    const { partialUpdateCache } = useGraphCacheStore()

    const updateFn = (entityId: string, update: Partial<Persisted<Entity>>) => {
      // Update the cache (if it exists)
      partialUpdateCache(entityId, {
        ...update,
        // We need to set this explicitly, since the cache will not update on old data
        updatedAt: new Date().toISOString(),
      })

      // This will run asynchonously. The cache will be updated with the new entity
      // when the API call is successful in another hook.
      apiUpdate(entityId, update)
    }

    return updateFn
  }

  const useEntityChildren = (props: {
    parentId?: string | null
    filter?: EntityType | FilterFunc
    // skip the query
    skip?: boolean
    defaultSort?: boolean
    includeSys?: boolean
    // Poll every x ms
    pollingInterval?: number
  }) => {
    const { filter, includeSys } = props
    const { entityCache } = useGraphCacheStore()

    const status = graphHooks.useEntityChildren(props)

    // We want to inject the cached items that are newer into the result

    // Get all the cached entries that have the same parent ID and are not in the apiEntries
    const cachedChildren = Array.from(entityCache.values())
    const candidateEntities = [...status.entities, ...cachedChildren].filter(
      childFilter({ parentId: props.parentId, filter, includeSys })
    )
    const seenIds = new Set<string>()

    const entities = candidateEntities
      .map(entity => {
        if (seenIds.has(entity.id)) {
          return null
        }
        seenIds.add(entity.id)
        const cached = entityCache.get(entity.id)
        const useCache = cached && cached.updatedAt > entity.updatedAt
        return useCache ? cached : entity
      })
      .filter(Boolean)

    return {
      ...status,
      entities,
    }
  }

  const useEntityCreate = <TEntityType extends Entity>() => {
    const apiCreate = graphHooks.useEntityCreate<TEntityType>()
    const { entityCache, updateCache } = useGraphCacheStore()

    const createFn = async (props: { entity: TEntityType; parent: Persisted<Entity> | null; isPrivate?: boolean }) => {
      // If the entity has an ID, we cache it immediately
      const { entity } = props
      if (entity.id) {
        updateCache({
          ...entity,
          createdAt: new Date().toISOString(),
          updateAt: new Date().toISOString(),
        } as unknown as Persisted<Entity>)
      }

      const result = await apiCreate.createEntity(props)

      if (result) {
        entityCache.set(result.id, result)
      }

      return result
    }
    return {
      ...apiCreate,
      createEntity: createFn,
    }
  }

  const useEntitiesById = (ids: string[]) => {
    const status = graphHooks.useEntitiesById(ids)
    const { entityCache, updateCache } = useGraphCacheStore()

    useEffect(() => {
      if (!status.isSuccess) {
        return
      }
      for (const entity of status.entities) {
        updateCache(entity)
      }
    }, [status.entities, status.isSuccess, updateCache])

    const entities = status.entities.map(entity =>
      getLatestEntry({ id: entity.id, candidate: entity, cache: entityCache })
    )
    const existingEntities = status.existingEntities.map(entity =>
      getLatestEntry({ id: entity.id, candidate: entity, cache: entityCache })
    )

    return {
      ...status,
      entities,
      existingEntities,
    }
  }

  const useEntityTrash = (entity?: Persisted<Entity>) => {
    const { updateCache } = useGraphCacheStore()
    const apiTrash = graphHooks.useEntityTrash(entity)
    const trashFn = () => {
      if (!entity) {
        return
      }
      updateCache({
        ...entity,
        trash: true,
        updatedAt: new Date().toISOString(),
      })

      apiTrash()
    }
    return trashFn
  }

  const useEntityDelete = () => {
    const [deleteEntity, deleteEntityResult] = graphHooks.useEntityDelete()
    const { entityCache } = useGraphCacheStore()
    const deleteFn = async ({ entity }: { entity: Persisted<Entity> }) => {
      // Update the cache optimistically
      entityCache.delete(entity.id)
      return deleteEntity({ entity })
    }

    return [deleteFn, deleteEntityResult]
  }

  const useProfiles = (
    props: {
      userIds?: string[]
      includeAssistant?: boolean
    } = {}
  ) => {
    const status = graphHooks.useProfiles(props)
    return status
  }

  const useMembers = (entity?: Persisted<Entity>) => {
    const memberIds = [entity?.userId, ...(entity?.acls?.map(acl => acl.principal) ?? [])].filter(Boolean)

    const status = useProfiles({
      userIds: memberIds,
      includeAssistant: false,
    })
    return {
      ...status,
      members: status.profiles,
    }
  }

  const useEntityChild = <T extends Entity>(props: UseChildren) => {
    const status = graphHooks.useEntityChild<T>(props)

    return status
  }

  const useEntities = <T extends Entity>(props: {
    /** Use only root entities, i.e., entities that have no parents */ root?: boolean
    /** Load only partial */
    partial?: boolean
    showTrash?: boolean
    filter?: ElementType | FilterFunc
    /** Load owned entities */
    owned?: boolean
    // polling interval (in ms)
    pollingInterval?: number
  }) => {
    const status = graphHooks.useEntities<T>(props)

    const { entityCache } = useGraphCacheStore()

    // Update with entries from the cache
    const entities = status?.entities.map(e => getLatestEntry({ id: e.id, candidate: e, cache: entityCache })) ?? []

    return { ...status, entities }
  }

  return {
    ...graphHooks,
    useEntityCreate,
    useEntity,
    useEntityUpdate,
    useEntityChildren,
    useEntitiesById,
    useMembers,
    useEntityChild,
    useEntityTrash,
    useEntityDelete,
    useEntities,
  }
}
