import { createApi } from '@reduxjs/toolkit/query/react'
import type { EntityVersion, Invite, ShareItem, Slug } from '@tunasong/models'
import { decorateParentAndACLs, dedupe, isStoredObject, logger, removeEntityReserved } from '@tunasong/models'
import type { ACL, Edge, EdgeType, Entity, EntityType, Persisted } from '@tunasong/schemas'
import type { EntitySync } from '@tunasong/sync-lib'
import { entitiesSlice } from '../features/entities/entities-slice.js'
import baseQuery, { createCachedQuerySpecHelper } from './base-query.js'
import { clearYDocCache } from './ydoc-cache.js'

/** StartKey for API. This is opaque for clients (but is a url encoded JSON string) */
export type StartKey = string

/** The default fields to load when loading   all entities */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultFields = [
  'id',
  'parentId',
  'userId',
  'type',
  'acls',
  'name',
  'tags',
  'starred',
  'media',
  'description',
  /** Folder */
  'options',
  /** Audio */
  'url',
  'storage',
  'features',
  'overdub',
  'regions',
  /** Playlist */
  'mediaIds',
  /** Common */
  'trash',
  'metadata',
  'createdAt',
  'updatedAt',
]

/** id for partial list for pagination @see https://redux-toolkit.js.org/rtk-query/usage/pagination#automated-re-fetching-of-paginated-queries */
export const PARTIAL_LIST = 'PARTIAL_LIST'

// const loadAllProps = { partial: true, trash: false, fields: defaultFields }

// Define a service using a base URL and expected endpoints

// Create a local query helper
const apiName = 'entities-api'
const cachedQuerySpec = createCachedQuerySpecHelper(apiName)

const tagTypes = ['Entity', 'EntityEdges', 'EntityBacklinks', 'ChildEntities', 'RootEntities'] as const
type TagType = (typeof tagTypes)[number]
export const entitiesApi = createApi({
  reducerPath: apiName,
  tagTypes,
  baseQuery,
  keepUnusedDataFor: 5,

  endpoints: builder => ({
    loadEntity: builder.query<Persisted<Entity>, { id: string }>({
      providesTags: (result, error, id) => [{ type: 'Entity', id: id.id }],

      query: cachedQuerySpec('loadEntity', ({ id }) => ({
        url: `entities/${id}`,
      })),
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ meta }) => {
          const eTag = meta?.eTag
          if (eTag) {
            dispatch(entitiesSlice.actions.setEtag({ id: ids.id, eTag }))
          }
        })
      },
    }),
    timeTravelChanges: builder.query<EntitySync[], { id: string }>({
      query: ({ id }) => `entities/${id}/timetravel`,
    }),
    timeTravel: builder.query<Persisted<Entity>, { entityId: string; createdAtId: string }>({
      // keepUnusedDataFor: 0,
      query: ({ entityId, createdAtId }) => `entities/${entityId}/timetravel?at=${createdAtId}`,
    }),

    loadEntityEdges: builder.query<Edge[], { source: string; target?: string }>({
      providesTags: (result, error, { source }) => [{ id: source, type: 'EntityEdges' }],
      query: cachedQuerySpec('loadEntityEdges', ({ source, target }) => ({
        url: `entities/${source}/edges${target ? `?target=${target}` : ''}`,
      })),
    }),

    loadEntityEdgesByIds: builder.query<Edge[], { sources: string[]; target?: string; relation?: EdgeType }>({
      // providesTags: (result, error, { source, target, relation }) => [{ id: source, type: 'EntityEdges' }],

      query: cachedQuerySpec('loadEntityEdgesByIds', ({ sources, target, relation }) => ({
        url: `entities/edges?sourceIds=${sources.join(',')}${target ? `&target=${target}` : ''}${relation ? `&relation=${relation}` : ''}`,
      })),
    }),

    /** Load the entities on the target side of an edge */
    loadEdgeEntitiesByIds: builder.query<
      Persisted<Entity>[],
      { sourceIds: string[]; target?: string; relation?: EdgeType }
    >({
      // providesTags: (result, error, { source, target, relation }) => [{ id: source, type: 'EntityEdges' }],
      query: cachedQuerySpec('loadEdgeEntitiesByIds', ({ sourceIds, target, relation }) => ({
        url: `entities/edge-entities?sourceIds=${sourceIds.join(',')}${target ? `&target=${target}` : ''}${relation ? `&relation=${relation}` : ''}`,
      })),
    }),

    createEntityEdge: builder.mutation<Edge, { edge: Edge }>({
      // we maintain these manually in onQueryStarted
      invalidatesTags: edge => {
        if (!edge) {
          return []
        }
        // logger.debug('Create edge invalidates EntityEdges tag', edge.source)
        return [
          { type: 'EntityEdges', id: edge.source },
          { type: 'EntityBacklinks', id: edge.target },
        ]
      },

      query: ({ edge }) => ({
        method: 'POST',
        url: `entities/${edge.source}/edges`,
        body: edge,
      }),
      onQueryStarted: async ({ edge }, api) => {
        const { dispatch } = api

        /** If the entity has an id we push it to the child list immediately */
        const { source, target } = edge

        // If we have not loaded children before we need to upsert
        const existingEdges =
          entitiesApi.endpoints.loadEntityEdges.select({ source, target })(api.getState()).data ?? []
        const existingBacklinks =
          entitiesApi.endpoints.loadEntityBacklinks.select({ id: target })(api.getState()).data ?? []
        dispatch(entitiesApi.util.upsertQueryData('loadEntityEdges', { source, target }, [...existingEdges, edge]))
        dispatch(entitiesApi.util.upsertQueryData('loadEntityBacklinks', { id: target }, [...existingBacklinks, edge]))
      },
    }),
    deleteEntityEdge: builder.mutation<void, Edge>({
      invalidatesTags: (result, error, { source, target }) => {
        logger.debug(`Delete edge invalidates EntityEdges ${source} tag and EntityBacklinks ${target}`)
        return [
          { type: 'EntityEdges', id: source },
          { type: 'EntityBacklinks', id: target },
        ]
      },

      query: edge => ({
        method: 'DELETE',
        url: `entities/${edge.source}/edges`,
        body: edge,
      }),
    }),
    loadEntityBacklinks: builder.query<Edge[], { id: string }>({
      providesTags: (result, error, id) => [{ type: 'EntityBacklinks', id: id.id }],
      query: cachedQuerySpec('loadEntityBacklinks', ({ id }) => ({
        url: `entities/${id}/backlinks`,
      })),
    }),
    loadEntities: builder.query<Persisted<Entity>[], { ids: string[] }>({
      providesTags: result => {
        const tags = result?.map(i => ({ type: 'Entity' as const, id: i.id })) ?? []
        return tags
      },
      query: cachedQuerySpec('loadEntities', ({ ids }) => ({
        url: `entities/?ids=${encodeURIComponent(dedupe(ids).join(','))}`,
      })),
    }),
    /** @deprecated */
    loadEntityPublic: builder.query<{ entity: Persisted<Entity>; authorizedUrl: string | null }, { id: string }>({
      providesTags: (result, error, { id }) => [{ type: 'Entity' as const, id }],
      query: ({ id }) => `entities/${id}/public`,
      transformResponse: async (entity: Persisted<Entity>, meta) => {
        const authorizedUrl = isStoredObject(entity) ? (meta?.response?.headers.get('authorized-url') ?? null) : null
        return { entity, authorizedUrl }
      },
    }),
    loadEntityHistory: builder.query<EntityVersion[], { id: string }>({
      query: ({ id }) => `entities/${id}/history`,
    }),
    restoreEntityVersion: builder.mutation<Persisted<Entity>, { version: EntityVersion }>({
      invalidatesTags: (result, error, { version }) => [{ type: 'Entity', id: version.id }],
      query: ({ version }) => `entities/${version.id}/restore?version=${version.version}`,
      onQueryStarted: async ({ version }, { dispatch, queryFulfilled }) => {
        /** Clear the YDoc cache for the entity */
        clearYDocCache(version.id)
        queryFulfilled.then(({ data: entity }) => {
          dispatch(entitiesApi.util.upsertQueryData('loadEntity', { id: entity.id }, entity))
        })
      },
    }),
    /** Restore to a previous YDoc. Just an update - a bit of a hack...p */
    entityTimeTravelRestore: builder.mutation<Persisted<Entity>, { id: string; timeTravelTo: string }>({
      invalidatesTags: (result, error, { id }) => [{ type: 'Entity', id }],

      query: ({ timeTravelTo, id }) => ({
        url: `entities/${id}/restore?timeTravelTo=${timeTravelTo}`,
      }),
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        /** Clear the YDoc cache for the entity */
        clearYDocCache(id)
        queryFulfilled.then(({ data }) => {
          dispatch(entitiesApi.util.upsertQueryData('loadEntity', { id: data.id }, data))
        })
      },
    }),
    loadAllEntities: builder.query<
      Persisted<Entity>[],
      {
        partial?: boolean
        trash?: boolean
        fields?: string[]
      }
    >({
      providesTags: ['RootEntities', { type: 'Entity' }],

      query: cachedQuerySpec('loadAllEntities', ({ partial, trash, fields = [] }) => ({
        url: `entities/?fields=${fields.join(',')}${partial ? '&partial=true' : ''}${trash ? '&trash=true' : ''}`,
      })),
    }),
    /** Partial variant of loadAll, typically used for trash */
    loadAllEntitiesPartial: builder.query<
      { entities: Persisted<Entity>[]; resumeKey: StartKey | null },
      {
        partial?: boolean
        trash?: boolean
        fields?: string[]
        limit?: number
        startKey?: string | null
      }
    >({
      providesTags: ['RootEntities', { type: 'Entity', id: PARTIAL_LIST }],
      query: cachedQuerySpec('loadAllEntitiesPartial', ({ partial, trash, fields = [], startKey, limit = 100 }) => ({
        url: `entities/?fields=${fields.join(',')}${partial ? '&partial=true' : ''}${trash ? '&trash=true' : ''}${
          limit ? '&limit=' + limit : ''
        }${startKey ? '&startKey=' + startKey : ''}`,
      })),

      transformResponse: (baseQueryReturnValue, meta) => {
        const resumeKey = meta?.resumeKey ?? null
        return { entities: baseQueryReturnValue as Persisted<Entity>[], resumeKey }
      },
    }),

    /** Child entities are used for e.g., chat comments. When loading incrementally, we need to invalidate */
    loadChildEntitiesPartial: builder.query<
      { entities: Persisted<Entity>[]; resumeKey: StartKey | null },
      {
        parentId: string
        type?: EntityType
        fields?: string
        limit?: number
        startKey?: string | null
      }
    >({
      providesTags: (result, error, { parentId }) => {
        const entityTags = result?.entities?.map(r => ({ type: 'Entity' as const, id: r.id })) ?? []
        return [{ type: 'ChildEntities', id: parentId }, { type: 'ChildEntities', id: PARTIAL_LIST }, ...entityTags]
      },
      transformResponse: async (baseQueryReturnValue, meta) => {
        const resumeKey = meta?.resumeKey ?? null
        return { entities: baseQueryReturnValue as Persisted<Entity>[], resumeKey }
      },
      query: cachedQuerySpec('loadChildEntitiesPartial', ({ parentId, type, fields, startKey, limit }) => ({
        url: `entities/?parentId=${parentId}${fields ? '&fields=' + fields : ''}${type ? '&type=' + type : ''}${
          limit ? '&limit=' + limit : ''
        }${startKey ? '&startKey=' + startKey : ''}`,
      })),
    }),
    loadChildEntities: builder.query<
      Persisted<Entity>[],
      {
        parentId: string
        type?: EntityType
        fields?: string
      }
    >({
      providesTags: (result, error, { parentId }) => {
        const entityTags = result?.map(r => ({ type: 'Entity' as const, id: r.id })) ?? []
        return [{ type: 'ChildEntities', id: parentId }, ...entityTags]
      },

      query: cachedQuerySpec('loadChildEntities', ({ parentId, type, fields }) => ({
        url: `entities/?parentId=${parentId}${fields ? '&fields=' + fields : ''}${type ? '&type=' + type : ''}`,
      })),
    }),
    createEntity: builder.mutation<
      Persisted<Entity>,
      { entity: Entity; parent: Persisted<Entity> | null; isPrivate?: boolean }
    >({
      invalidatesTags: result => {
        /** For root entities we need to invalidate the root */
        if (!result?.parentId) {
          return [{ type: 'RootEntities' as const }]
        }
        logger.debug('Create entity invalidates ChildEntities tag', result.parentId)
        return [{ type: 'ChildEntities', id: result?.parentId }]
      },
      query: ({ entity, parent, isPrivate = false }) => ({
        url: `entities/`,
        method: 'POST',
        body: decorateParentAndACLs({ entity, parent, isPrivate }),
      }),
    }),
    cloneEntity: builder.mutation<Persisted<Entity>, { entityId: string; name?: string }>({
      invalidatesTags: result => {
        /** For root entities we need to invalidate the root */
        if (!result?.parentId) {
          return [{ type: 'RootEntities' as const }]
        }
        return [{ type: 'ChildEntities', id: result?.parentId }]
      },
      query: ({ entityId, name }) => ({
        url: `entities/${entityId}/clone`,
        method: 'POST',
        body: {
          name,
        },
      }),
    }),
    updateEntity: builder.mutation<
      Persisted<Entity>,
      { id: string; partialEntity: Partial<Entity>; eTag: string | null; upsert?: boolean }
    >({
      /** Reload all entities (e.g., child entities) after updating an entity */
      invalidatesTags: (result, error, { id, partialEntity }) => {
        const invalidation: { type: TagType; id: string | undefined }[] = [
          { type: 'Entity', id },
          { type: 'ChildEntities', id: result?.parentId },
        ]
        /**
         * Change the parentId or without parent => need to reload root
         * @note MUST filter out null values otherwise the invalidation breaks
         */
        if (!result?.parentId || partialEntity.parentId) {
          invalidation.push({ type: 'RootEntities', id: undefined })
        }
        logger.debug('Update entity invalidates ChildEntities tag', invalidation)
        return invalidation
      },
      query: ({ id, partialEntity, eTag, upsert = false }) => {
        const headers: Record<string, string> = {}
        /**
         * We need a strategy to update entities that have changed server-side.
         * Document updates are handled using Y - so no worries there.
         * However, we must include yDoc in the eTag, otherwise clients will not get the last content.
         * Single attribute updates are accepted below.
         * Multi-attribute updates will require eTag to be the same.
         */
        const numKeysUpdated = Object.keys(partialEntity).length
        if (eTag && numKeysUpdated > 1) {
          headers['if-match'] = eTag
        }
        const body = removeEntityReserved(partialEntity)
        return {
          url: `entities/${id}`,
          method: upsert ? 'PUT' : 'PATCH',
          headers,
          body,
        }
      },
    }),
    deleteEntity: builder.mutation<void, { entity: Persisted<Entity> }>({
      /** Reload all entities (e.g., child entities) after updating an entity */
      invalidatesTags: (result, error, { entity }) => [
        { type: 'RootEntities' },
        { type: 'ChildEntities', id: entity.parentId ?? undefined },
        { type: 'ChildEntities', id: PARTIAL_LIST },
        { type: 'Entity', id: entity.id },
        { type: 'Entity', id: PARTIAL_LIST },
      ],
      query: ({ entity }) => ({
        url: `entities/${entity.id}`,
        method: 'DELETE',
      }),
    }),
    transferEntity: builder.mutation<void, { entity: Persisted<Entity>; toUserId: string }>({
      invalidatesTags: (result, error, { entity }) => [
        { type: 'RootEntities' },
        { type: 'ChildEntities', id: entity.parentId ?? undefined },
        { type: 'ChildEntities', id: PARTIAL_LIST },
        { type: 'Entity', id: entity.id },
        { type: 'Entity', id: PARTIAL_LIST },
      ],
      query: ({ entity, toUserId }) => ({
        url: `entities/${entity.id}/transfer`,
        method: 'POST',
        body: { toUserId },
      }),
    }),
    /** Social API */
    share: builder.mutation<Persisted<ShareItem>, { item: Persisted<ShareItem>; acls: ACL[] }>({
      /**
       * Whenever we share an item, we need to reload entities from the server,
       * because child entities may have been updated.
       */
      invalidatesTags: (result, error, { item }) => [
        { type: 'Entity' as const, id: item.id },
        { type: 'ChildEntities' as const, id: item.id },
      ],

      query: ({ item, acls }) => ({
        url: `social/shares`,
        method: 'POST',
        body: { id: item.id, acls },
      }),
      onQueryStarted: async ({ item, acls }, { dispatch }) => {
        /** Update ACLs in local item */
        dispatch(
          entitiesApi.util.updateQueryData('loadEntity', { id: item.id }, draft => {
            Object.assign(draft, { acls })
          })
        )
      },
    }),

    invite: builder.mutation<Slug, { invite: Invite }>({
      query: ({ invite }) => ({
        url: `social/invite`,
        method: 'POST',
        body: invite,
      }),
    }),
  }),
})
