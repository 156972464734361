import { createApi } from '@reduxjs/toolkit/query/react'
import { dedupe } from '@tunasong/models'
import type { Persisted, Profile } from '@tunasong/schemas'
import baseQuery, { createCachedQuerySpecHelper } from './base-query.js'

// Define a service using a base URL and expected endpoints
/**
 * This type is self referencing - https://github.com/arktypeio/arktype/discussions/740#discussioncomment-5777358
 */

const apiName = 'profiles-api'
const cachedQuerySpec = createCachedQuerySpecHelper(apiName)

export const profilesApi = createApi({
  reducerPath: apiName,
  tagTypes: ['Profile'],
  /** Keep profiles for an hour */
  keepUnusedDataFor: 3600,

  baseQuery,
  endpoints: builder => ({
    /** @todo we get instantiation depth problems using Profile here */
    loadProfiles: builder.query<Persisted<Profile>[], { userIds: string[] }>({
      keepUnusedDataFor: 3600,
      providesTags: result => result?.map(r => ({ type: 'Profile' as const, id: r.userId })) ?? [],

      query: cachedQuerySpec('loadProfiles', ({ userIds }) => ({
        url: `profiles/?ids=${encodeURIComponent(dedupe(userIds).join(','))}`,
      })),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        for (const profile of result.data) {
          /** to avoid infinite type recursion we cast profile to never */
          dispatch(profilesApi.util.upsertQueryData('loadProfile', { emailOrUserId: 'PUBLIC' }, profile as never))
        }
      },
    }),
    loadProfile: builder.query<Persisted<Profile>, { emailOrUserId: string }>({
      providesTags: result => [{ type: 'Profile' as const, id: result?.userId }],
      keepUnusedDataFor: 3600,
      query: cachedQuerySpec('loadProfile', ({ emailOrUserId }) => ({
        url: `profiles/${encodeURIComponent(emailOrUserId)}`,
      })),
    }),
    updateProfile: builder.mutation<Persisted<Profile>, { id: string; profile: Partial<Profile> }>({
      /** @note we update the Redux cache on query, so we don't need to invalidate these tags here */
      // invalidatesTags: result => [{ type: 'Profile' as const, id: result?.userId }],
      query: ({ id, profile }) => ({
        url: `profiles/${id}`,
        method: 'PATCH',
        body: profile,
      }),
      /** Immediately update the cache on update */
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          profilesApi.util.updateQueryData('loadProfile', { emailOrUserId: id }, draft => {
            Object.assign(draft, patch.profile)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})
