/**
 * Store the yDoc in S3
 */

import { GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3'
import { StorageClient } from '@tunasong/connector-s3'
import { logger } from '@tunasong/models'
import type { S3Storage, Storage } from '@tunasong/schemas'
import invariant from 'tiny-invariant'

const client = new StorageClient({})

export const getStorageObjectKey = ({ entityId, userId }: { entityId: string; userId: string }) =>
  `users/${userId}/docs/${entityId}/doc.ydoc`

const storeDoc = async ({
  yDocAsUpdates,
  bucketName,
  entityId,
  userId,
  docType,
}: {
  /** The yDoc encoded as updates */
  yDocAsUpdates: Uint8Array
  bucketName: string
  entityId: string
  userId: string
  docType: string
}) => {
  invariant(bucketName, 'No bucketName')
  invariant(entityId, 'No entityId')
  invariant(yDocAsUpdates, 'No stringEncodedyDoc')

  const objectKey = getStorageObjectKey({ entityId, userId })

  const result = await client.send(
    new PutObjectCommand({
      Bucket: bucketName,
      Key: objectKey,
      ContentType: 'application/octet-stream',
      /** yDoc encoded as updates */
      Body: yDocAsUpdates,
    })
  )
  const { VersionId: versionId } = result
  return {
    versionId,
    filename: objectKey,
    type: 'S3',
    docType: docType as S3Storage['docType'],
  } satisfies S3Storage
}

/** Retrieve the doc from S3 as Uint8Array */
const getDoc = async ({
  bucketName,
  storage,
  fallbackToLatest = false,
}: {
  bucketName: string
  storage: S3Storage
  // If the specified versionId is not found, return the latest version
  fallbackToLatest?: boolean
}) => {
  invariant(bucketName, 'No bucketName')
  invariant(storage, 'No storage')

  const { filename: objectKey, versionId } = storage

  // Primary attempt to get the specified version
  try {
    const result = await client.send(
      new GetObjectCommand({
        Bucket: bucketName,
        Key: objectKey,
        VersionId: versionId,
      })
    )
    const data = await result.Body?.transformToByteArray()
    return data ?? null
  } catch (error) {
    const isNoSuchVersionError =
      typeof error === 'object' && error !== null && 'Code' in error && error.Code === 'NoSuchVersion'

    logger.error(`Error getting doc version`, { objectKey, versionId, error, isNoSuchVersionError })

    if (!fallbackToLatest || !isNoSuchVersionError) {
      logger.error(`Error getting doc and NOT falling back to latest`, {
        error,
        isNoSuchVersionError,
        fallbackToLatest,
      })
      return null
    }
  }
  // A versioned object was requested, but the specified versionId is not found

  try {
    // If the specified versionId is not found, return the latest version
    const result = await client.send(
      new GetObjectCommand({
        Bucket: bucketName,
        Key: objectKey,
      })
    )
    const data = await result.Body?.transformToByteArray()
    return data ?? null
  } catch (error) {
    logger.error('Error getting latest version of doc', { objectKey, error })
  }

  return null
}

function isDocPath(param: { entityId?: string; userId: string; storage?: Storage }): param is {
  entityId: string
  userId: string
  storage: S3Storage
} {
  const { entityId, userId, storage } = param
  return Boolean(
    entityId &&
      storage?.type === 'S3' &&
      storage.docType === 'yDoc' &&
      (storage?.filename.startsWith(`users/${userId}/docs/${entityId}/`) ||
        // @todo remove this after migration
        storage?.filename.startsWith(`docs/${entityId}/`))
  )
}

export const S3Doc = {
  storeDoc,
  getDoc,
  isDocPath,
}
