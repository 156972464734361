import type { Entity, Persisted } from '@tunasong/schemas'
import type { UseChildren } from './graph-types.js'
import { includeElement, isTaggedSysEntity } from '@tunasong/models'

export const getLatestEntry = <T extends Persisted<unknown>>({
  id,
  candidate,
  cache,
}: {
  id?: string | null
  cache: Map<string, T>
  // The candidate from the API - this may be outdated
  candidate?: T | null
}) => {
  if (!id) {
    return undefined
  }
  const cacheEntry = cache.get(id)
  if (!cacheEntry) {
    return candidate
  }
  if (!candidate) {
    return cacheEntry
  }

  // Always return the newest Entry
  if (cacheEntry.updatedAt > candidate.updatedAt) {
    return cacheEntry
  }
  return candidate
}

export const childFilter =
  ({ parentId, filter: type, includeSys }: UseChildren) =>
  (child: Entity) =>
    child &&
    child.parentId === parentId &&
    includeElement(child, type) &&
    !child.trash &&
    (includeSys ? true : !isTaggedSysEntity(child))
