import { createApi } from '@reduxjs/toolkit/query/react'
import type { SearchSummary } from '@tunasong/models'
import type { SearchRequest, SearchResult } from '@tunasong/schemas'
import baseQuery, { createCachedQuerySpecHelper } from './base-query.js'

// Define a service using a base URL and expected endpoints
const apiName = 'search-api'
const cachedQuerySpec = createCachedQuerySpecHelper(apiName)

export const searchApi = createApi({
  reducerPath: apiName,
  keepUnusedDataFor: 60,
  baseQuery,
  endpoints: builder => ({
    search: builder.query<SearchResult<SearchSummary>, { query: SearchRequest; queryType: string }>({
      query: cachedQuerySpec('search', ({ query, queryType }) => ({
        url: `search/?queryType=${queryType}&query=${encodeURIComponent(JSON.stringify(query))}`,
      })),
    }),
  }),
})
