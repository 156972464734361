import { logger } from '@tunasong/models'
import type { Middleware } from 'redux'
import invariant from 'tiny-invariant'
import { entitiesSlice } from './entities-slice.js'

import { useGraphCacheStore } from '@tunasong/graph-cache'

/**
 * Sync entities actions with the cache.
 */

const middleware: Middleware = // Most middleware do not modify the dispatch return value
  () => next => action => {
    const { partialUpdateCache, updateCache } = useGraphCacheStore.getState()

    if (entitiesSlice.actions.receiveEntity.match(action)) {
      logger.debug('Middleware: Received entity', action.payload.entity)

      // Trigger an update of the cache.
      updateCache(action.payload.entity)
    } else if (entitiesSlice.actions.receivePartialEntity.match(action)) {
      logger.debug('Middleware: Received partial entity', action.payload)
      invariant(action.payload.id, 'Partial entity must have an id')

      partialUpdateCache(action.payload.id, action.payload)
    }
    /** Always apply the action to other reducer(s)  */
    next(action)
  }

export default middleware
