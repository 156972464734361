import { logger } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import { create } from 'zustand'

/** Global graph cache store */
export const useGraphCacheStore = create<{
  entityCache: Map<string, Persisted<Entity>>
  clearCache: () => void
  getEntry: (id: string) => Persisted<Entity> | undefined
  removeEntry: (id: string) => Map<string, Persisted<Entity>>
  updateCache: (entity: Persisted<Entity>) => Persisted<Entity>
  updateCacheEntries: (update: Persisted<Entity>[]) => Persisted<Entity>[]
  partialUpdateCache: (id: string, partialEntity: Partial<Persisted<Entity>>) => Persisted<Entity> | undefined
}>((set, get) => ({
  clearCache: () => set({ entityCache: new Map() }),
  entityCache: new Map<string, Persisted<Entity>>(),
  getEntry: (id: string) => get().entityCache.get(id),
  updateCache: (update: Persisted<Entity>) => {
    const { entityCache } = get()
    const cacheEntry = entityCache.get(update.id)
    if (cacheEntry && cacheEntry.updatedAt >= update.updatedAt) {
      return cacheEntry
    }

    set({ entityCache: new Map(entityCache).set(update.id, update) })

    return update
  },
  updateCacheEntries: (update: Persisted<Entity>[]) => {
    const newMap = new Map(get().entityCache)
    const entries: Persisted<Entity>[] = Array.isArray(update) ? update : [update]
    const newest: Persisted<Entity>[] = []

    for (const entry of entries) {
      const cacheEntry = newMap.get(entry.id)
      if (cacheEntry && cacheEntry.updatedAt >= entry.updatedAt) {
        newest.push(cacheEntry)
        continue
      }
      newest.push(entry)
      // Update the cache
      newMap.set(entry.id, entry)
    }
    set({ entityCache: newMap })

    return newest
  },
  removeEntry: (id: string) => {
    const { entityCache } = get()
    const success = entityCache.delete(id)
    if (!success) {
      logger.warn('Attempted to remove an entry that does not exist in the cache', id)
      return entityCache
    }
    const updatedCache = new Map(entityCache)
    set({ entityCache: updatedCache })
    return updatedCache
  },
  partialUpdateCache: (id: string, partialEntity: Partial<Persisted<Entity>>) => {
    const { entityCache } = get()
    const cacheEntry = entityCache.get(id)

    if (!cacheEntry) {
      return undefined
    }

    if (!partialEntity.updatedAt) {
      logger.warn('Partial entity update does not have an updatedAt field, not updating cache', partialEntity)
      return cacheEntry
    }

    if (cacheEntry.updatedAt >= partialEntity.updatedAt) {
      return cacheEntry
    }

    const updated = {
      ...cacheEntry,
      ...partialEntity,
    }

    set({ entityCache: new Map(entityCache).set(id, updated) })

    return updated
  },
}))
