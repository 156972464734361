import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'
import { audioFeatures } from '../interfaces/audio-features.js'
import type { AudioFeatureType } from '../interfaces/audio-features.js'
import { storageSchema, storageUrlsSchema } from '../interfaces/storage.js'

const baseSchema = z.object({
  type: z.string(),
  data: z.string().optional().describe('Storage path to the JSON data for the feature'),
  plot: z.string().optional().describe('Storage path to the Image plot for the feature'),
})

export type AudioFeatureUrls = Pick<z.infer<typeof baseSchema>, 'data' | 'plot'>

const baseFeatureSchema = baseSchema.extend({
  type: z.literal('base'),
  duration: z.number().describe('Duration of the audio, in seconds'),
  sampleRate: z.number().optional().describe('Sample rate of the audio, in Hz'),
  channels: z.number().optional().describe('Number of channels in the audio'),
  fileSize: z.number().optional().describe('Size of the audio file, in bytes'),
})

const beatsFeatureSchema = baseSchema.extend({
  type: z.literal('beats'),
  tempo: z.number().describe('Tempo of the audio, in beats per minute'),
})

const onsetsFeatureSchema = baseSchema.extend({
  type: z.literal('onsets'),
})

const powerFeatureSchema = baseSchema.extend({
  type: z.literal('power'),
})

const peaksFeatureSchema = baseSchema.extend({
  type: z.literal('peaks'),
  temporary: z.boolean().optional().describe('Whether the feature is temporary for UI/UX purposes'),
})
const chromaFeatureSchema = baseSchema.extend({
  type: z.literal('chroma'),
})

const chordsFeatureSchema = baseSchema.extend({
  type: z.literal('chords'),
})
export type AudioChordsFeature = z.infer<typeof chordsFeatureSchema>
export function isAudioChordsFeature(node: unknown): node is AudioChordsFeature {
  return chordsFeatureSchema.safeParse(node).success
}

export const audioFeatureSchema = z.union([
  baseFeatureSchema,
  beatsFeatureSchema,
  onsetsFeatureSchema,
  peaksFeatureSchema,
  chromaFeatureSchema,
  chordsFeatureSchema,
  powerFeatureSchema,
])

export type AudioFeature = z.infer<typeof audioFeatureSchema>
export function isAudioFeature(node: unknown): node is AudioFeature {
  return audioFeatureSchema.safeParse(node).success
}

export function isAudioFeatureMap(node: unknown): node is Record<AudioFeatureType, AudioFeature> {
  return z.record(audioFeatureSchema).safeParse(node).success
}

const separationTypes = ['vocals', 'bass', 'drums', 'other'] as const
const mediaTypes = ['recording', 'clip', 'overdub', ...separationTypes] as const
export type MediaType = (typeof mediaTypes)[number]

export const audioSchema = baseEntitySchema.extend({
  type: z.literal('audio'),
  name: z.string().optional(),
  // Lax union to allow for legacy values
  features: z
    .object({
      base: baseFeatureSchema.optional(),
      beats: beatsFeatureSchema.optional(),
      onsets: onsetsFeatureSchema.optional(),
      peaks: peaksFeatureSchema.optional(),
      chroma: chromaFeatureSchema.optional(),
      chords: chordsFeatureSchema.optional(),
      power: powerFeatureSchema.optional(),
    })
    .optional(),

  // audio has separation tracks as children
  separation: z.boolean().optional(),
  // type of media
  mediaType: z.enum(mediaTypes).optional(),

  storage: storageSchema.optional(),
  storageUrls: storageUrlsSchema
    .extend({
      features: z
        .record(
          z.enum(audioFeatures),
          z.object({
            data: z.string().optional(),
            plot: z.string().optional(),
          })
        )
        .optional(),
    })
    .optional(),
})

export type Audio = z.infer<typeof audioSchema>
/** @deprecated use Audio instead */
export type AudioMedia = z.infer<typeof audioSchema>

export function isAudio(node: unknown): node is Audio {
  return audioSchema.safeParse(node).success
}

/** Is audio separation, i.e., vocals, bass, drums etc. */
export function isAudioSeparation(node: unknown): node is Audio {
  return Boolean(isAudio(node) && node.mediaType && separationTypes.includes(node.mediaType))
}
