import { baseEntitySchema } from '../entity.js'
import { z } from 'zod'

export const refSchema = baseEntitySchema.extend({
  type: z.literal('ref'),
  entityId: z.string(),
  pending: z.boolean().optional(),
  overrides: z.record(z.any()).optional(),
})

export type EntityRef = z.infer<typeof refSchema>

export function isEntityRef(node: unknown): node is EntityRef {
  return refSchema.safeParse(node).success
}

export default refSchema
