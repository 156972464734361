import { z } from 'zod'
import type { CoreElement } from './core/slate/slate-element.js'
import type { SlateText } from './core/slate/slate-text.js'
import { coreEntityNames } from './core/entities/index.js'
import { extensionEntityNames } from './extensions/index.js'

/** Declare the custom editor element types here */
export const customElementTypeNames = ['ref'] as const

/** Declare the entity types you have here */
export const entityTypeNames = [
  'amp',
  'bass',
  'event',
  'drawing',
  'drums',
  'effect',
  'facade',
  'folder',
  'game',
  'guitar',
  'midi',
  'mic',
  'pdf',
  'note',
  'piano',
  'playlist',
  'poll',
  'pollresponse',
  'room',
  'tab',
  'score',
  'section',
  'sketch',
  'synth',
  'tracker',
  'trackerdata',
  'evernote',
  /** Don't remove this - these are the core entities */
  ...coreEntityNames,
  ...extensionEntityNames,
] as const

export type EntityType = (typeof entityTypeNames)[number]

/** List of all the Slate element and Entity type names */

const typedElementSchema = z.object({ type: z.string() })
export function isTypedElement(element?: unknown): element is z.infer<typeof typedElementSchema> {
  return typedElementSchema.safeParse(element).success
}

export type TunaDecendant = SlateText | CoreElement
