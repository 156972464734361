import { useContext, useEffect, useState } from 'react'
import type { PluginConfigSet } from './plugin-context.js'
import { PluginContext } from './plugin-context.js'
import type { TunaPlugin } from '../plugin-types.js'

export const usePlugins = (type: PluginConfigSet | null = 'all') => {
  const repo = useContext(PluginContext)

  const pluginCollection = type ? repo?.getCollection(type) : null
  // We use this to trigger a re-render on load
  const [localPluginCollection, setLocalPluginCollection] = useState<TunaPlugin[] | null>(null)

  useEffect(() => {
    if (!(repo && type) || pluginCollection || localPluginCollection) {
      return
    }

    repo.loadCollection(type).then(plugins => {
      setLocalPluginCollection(plugins)
    })
  }, [localPluginCollection, pluginCollection, repo, type])

  return pluginCollection ?? localPluginCollection
}
